import React from 'react';

class NotFound extends React.Component {
    render() {
        return (<div>Sorry, the page you were looking for couldn't be found.</div>);
    }
}

NotFound.displayName = 'NotFound';

export default NotFound;
